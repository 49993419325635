var $ = jQuery.noConflict();
var DFPid = 4848013;
var isMobile = false;


$(document).ready(function(){
  console.log('ready? ok!');
  if( $(window).width() < 767){
    isMobile = true;
    console.log("its mobile width");
    console.log(isMobile);

  }
  check_for_ads_in_view();

  // HEAERMENU OPEN
  $('#site-menu-button').click(function(){
    $('body').toggleClass('open-menu');
  });

  $('#browse-locations button').click(function(){
    $(this).parent().toggleClass('active');
    $('.header-locations').slideToggle();
  });

  $('#mobile-search-toggle').click(function(){
    $('#masthead').toggleClass('mobile-search-active');
    if($('#masthead').hasClass('mobile-search-active')){
      $('#mobile-search input[type="text"]').focus();
    }
  });

  // search UX
  $('#site-header-search input[type=submit]').click(function(event){
    if ( $('#site-header-search input[type=text]').val() == "" ) {
      event.preventDefault();
      $('#site-header-search input[type=text]').focus();
    }
  });

  /*
   * Homepage magazine
   */
   if ( $('.magazine-posts').length > 0 ) {
     console.log('slcikyyy');
     $('.magazine-posts').slick({
       prevArrow: $('.mag-prev'),
       nextArrow: $('.mag-next'),
       dots: false,
       slidesToShow: 3.25,
       infinite: false,
       responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 1.75,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          }
        },
      ]
     });
   }


  /*
   * Paragraph Drop Caps
   */
   if ( $('p.has-drop-cap').length > 0 ) {
     $('p.has-drop-cap').each(function(){
       dropCap($(this));
     });
   }

   /*
    * Pullquote Wrapping
    */
    if ( $('.wp-block-pullquote').length > 0 ) {
      $('.wp-block-pullquote blockquote > *:not(cite)').wrapInner("<span class='textbg-fix'></span>");
    }


    if( $('.northforker-gallery').length > 0 ){
      $('.northforker-gallery').each(function(x, elem){
        $(elem).find('.northforker-gallery-images').slick({
          prevArrow: $(elem).find('.northforker-gallery-nav .mag-prev'),
          nextArrow: $(elem).find('.northforker-gallery-nav .mag-next'),
          dots: false,
          adaptiveHeight: true,
        });
        $(elem).on('beforeChange', function(event, slick, currentSlide, nextSlide){
          console.log(nextSlide);
          $(elem).find(".northforker-gallery-captions span").hide();
          $(elem).find(".northforker-gallery-captions span[slideKey='" + nextSlide +"']").show();
          $(elem).find(".northforker-gallery-progress span").html(++nextSlide);
        });

        $(elem).find('.northforker-gallery-images').slick('setPosition');

      });
    }

    /*
     * Gallery post
     */
     if ( $('.gallery-toggle').length > 0 ) {
       $('.gallery-toggle').click(function(){
         $('.gallery-popup').toggleClass('active');
         $('body').toggleClass('hide-overflow');
       });
       $('.gallery-close').click(function(e){
         e.preventDefault();
         $('.gallery-toggle').click();
       })
     }


     /*
      * Vendor Gallery
      */
      if ( $('.vendor-gallery').length > 0 ) {
        $('.vendor-gallery').each(function(x, elem){
          $(elem).find('.vendor-gallery-slides').slick({
            nextArrow: $(elem).find('.vendor-gallery-next'),
            prevArrow: $(elem).find('.vendor-gallery-prev'),
            adaptiveHeight: false,
          });

          $(elem).find('.vendor-gallery-nav a').click(function(e){
            e.preventDefault();
            let key = $(this).attr('key');
            $(elem).find('.vendor-gallery-slides').slick('slickGoTo', key);
          });

          $(elem).on('beforeChange', function(event, slick, currentSlide, nextSlide){
            $(elem).find('.vendor-gallery-progress span').html(++nextSlide);
          });
        })
      }

    /*
     * Scrolly
     */
     $(window).scroll(function(){
       if($(window).scrollTop() > 0){
         $('body').addClass('scrolled');
       }else{
         $('body').removeClass('scrolled');
       }
     });


    //Ajax scripts
    $(".loadMore").on('click',function(){
      var args = JSON.parse($(this).attr('data-args'));
      var size = $(this).attr('data-size');
      var container = $(this).attr('data-container');
      var offset = $("."+container).find('article').length;
      if ( $(this).data('includeincount') ) {
        offset += $($(this).data('includeincount')).find('article').length;
      }
      console.log(offset);

      $(this).addClass('loading');
      var thisref = $(this);

      console.log(args);
      $.ajax({
          url: ajaxurl,
          data: {
              action: 'do_ajax',
              fn: 'loadMore',
              args: args,
              size: size,
              offset: offset
          },
          success: function(data){
            if(data !== null){
              console.log(data);
              if (!$.trim(data)){
                thisref.addClass('complete');
                thisref.html('No more posts to show.')
              } else {
                $("."+container).append(data);
              }
            }
          },
          complete:function(data){
            thisref.removeClass('loading');
          },
          error: function(errorThrown) {
            console.log(errorThrown);
            thisref.addClass('error');
            thisref.html('There was an error loading more posts.')
          }
      });

    });

    // newsletter signup
    // if ( $('#footer-signup form, .sidebar-email-signup-inputs form, #pull-out-sign-up form, .custom-form-wrapper form').length ) {
    //   $('#footer-signup form, .sidebar-email-signup-inputs form, #pull-out-sign-up form, .custom-form-wrapper form').submit(function(event){
    //     event.preventDefault();
    //     register($(this));
    //   });
    // }

    if ( $('.event-filter-select, #price-filter-checkbox').length ) {
      $('.event-filter-select').change(function(){
        $(this).attr('selected-filter', $(this).val());
      });
      $('.clear-select').click(function(){
        $(this).prev().val( $(this).prev().children().eq(0).attr('value') );
        $(this).prev().trigger('change');
      })
      $('.event-filter-select, #price-filter-checkbox').change(function(){
        const filterBy = "." + $('#category-filter').val() + "." + $('#month-filter').val() + "." + $('#location-filter').val() + ( $('#price-filter-checkbox')[0].checked ? ".price-free" : "" );
        $('article.home-event').hide();
        $(filterBy).css('opacity', 0);
        $(filterBy).show();
        $(filterBy).each(function(index) {
          const thisRef =$(this);
          setTimeout(function(){
            thisRef.animate({
              opacity: 1
            }, 500);
          }, Math.min(50 * index, 500));
        });
      });
    }

    if ( $('body').hasClass('post-template-template-special-content') && $('.wp-block-image.is-style-parallax').length ) {
      $('.wp-block-image.is-style-parallax img').each(function(){
        new simpleParallax( $(this).get(0), {
          scale: 1.2,
          orientation: 'up'
        });
      });
    }

    if ( $('.wprm-recipe-instruction').length ) {
      $('.wprm-recipe-instruction').on('click', function(e){
        if ( $(e.target).hasClass('wprm-recipe-instruction') ) {
          $(this).toggleClass('checked');
        }
      }).on('keypress', function(){
        $(this).trigger('click');
      })
    }

    if ( $('.wprm-recipe-ingredient').length ) {
      $('.wprm-recipe-ingredient').on('click', function(e){
        $(this).toggleClass('checked');
      }).on('keypress', function(){
        $(this).trigger('click');
      })
    }

    if ( $('.wprm-recipe-jump').length ) {
      $('.wprm-recipe-jump').click(function(e){
        e.preventDefault();
        let target = $( $(this).attr('href') ).offset().top - 92;
        let dist = Math.abs($(window).scrollTop() - target);
        console.log(dist);
        $([document.documentElement, document.body]).animate({
          scrollTop: target
        }, ((dist / 1200) * 1000));
      }).keypress(function(){
        $(this).trigger('click');
      });
    }
});









//DFP Scripts
function check_for_ads_in_view(){
	//console.log("checking for ads");
  var started = false;
  googletag.cmd.push(function() {
  if($('.dfp_block').length > 0){

		$('.dfp_block').each(function(){
// 			if($(window).scrollTop() + $(window).height() + 100 > $(this).offset().top && !$(this).hasClass('rendered') && $(window).scrollTop() - 300 < $(this).offset().top && !autoscroll){
					//console.log("getting The Ad");
          //if(started == false){
            started = true;
        var adData = $(this).data();
        var adId = adData.label;
        get_dfp_by_tag(adId);
				$(this).addClass('rendered');
      //}

			//}
		});


	}
});
}

function get_dfp_by_tag(tag){
	if($('#'+tag).length<1){
	$('#dfp_block_'+tag).append("<div id='"+tag+"'></div>");
  var slots = {};

	 googletag.display(tag);
	console.log('getting Ads ' + tag);
	console.log(googletag);
	}
var adSlug;
var adPagetype;
var adNewspaper;
var adCats;
var toplineCats;
var loop = true;
  //look through all PHP written Blocks
console.log($('.dfp_block'));
  googletag.cmd.push(function() {
    $('.dfp_block').each(function(){

      var newspapers = ["notOne","timesreview","north-shore-sun","riverhead-news","suffolk-times","shelter-island-reporter","notone2","northforker","notone3","southforker"]
      var adData = $(this).data();
      var adSizess = adData.size.split('x');
      if(adData.cats.split(",").length == 1){
       adCats = adData.cats;
      }else{
         adCats = adData.cats.split(",");
      }
      if (loop){toplineCats = adCats;}
      loop = false;
      console.log("this is toplinCates "+toplineCats);

      var adSize = adData.bannertype;
       adSlug = adData.slug;
       adPagetype = adData.pagetype;
       adNewspaper = newspapers[adData.newspaper];
      var adLabel = adData.label;
      var mapping;
      console.log(adSizess + "this is ad szies");
      slots[adLabel] = googletag.defineSlot('/4848013/'+adData.label, [[parseInt(adSizess[0]),parseInt(adSizess[1])]], adLabel).addService(googletag.pubads());
switch (adSize) {
  case 'topBanner':
   mapping = googletag.sizeMapping().
addSize([1024, 400], [970, 250]).
addSize([728, 400], [728, 90]).
addSize([0, 0], [320, 50]).
//addSize([0, 0], [88, 31]).
// Fits browsers of any size smaller than 640 x 480
build();
break;
case 'normalBanner':
 mapping = googletag.sizeMapping().
addSize([1050, 200], [970, 90]).
addSize([728, 300], [728, 90]).
addSize([0, 0], [300, 250]).
//addSize([0, 0], [88, 31]).
// Fits browsers of any size smaller than 640 x 480
build();

break;
case 'sidebarBox':
 mapping = googletag.sizeMapping().
addSize([1024, 768], [300, 250]).
build();

break;
case 'sidebarSkyscraper':
 mapping = googletag.sizeMapping().
addSize([768, 500], [300, 600]).
addSize([500, 500], [300, 250]).
//addSize([0, 0], [88, 31]).
// Fits browsers of any size smaller than 640 x 480
build();

break;
case 'inlineDesktopSwitch':
 mapping = googletag.sizeMapping().
addSize([768, 500], [400, 225]).
addSize([0, 0], [300, 250]).
//addSize([0, 0], [88, 31]).
// Fits browsers of any size smaller than 640 x 480
build();

break;
case 'inlineDesktop':
 mapping = googletag.sizeMapping().
addSize([760, 400], [400, 225]).
addSize([0, 0], []).
//addSize([0, 0], [88, 31]).
// Fits browsers of any size smaller than 640 x 480
build();

break;
case 'inlineMobile':
 mapping = googletag.sizeMapping().
addSize([320, 700], [300, 250]). // Tablet
addSize([760, 200], []). // Desktop
addSize([0, 0], []).
build();

    break;
  default:

}

slots[adLabel].setTargeting('Newspapers', 'southforker');
slots[adLabel].setTargeting('categories', adCats);
slots[adLabel].setTargeting('slug', adSlug);
slots[adLabel].setTargeting('pagetype', adPagetype);
slots[adLabel].defineSizeMapping(mapping);
console.log(slots[adLabel]);
    });

//Create incontent
var mobileAdSizes = ["inline_mobile_article_1","inline_mobile_article_2","inline_mobile_article_3","inline_mobile_article_4","inline_mobile_article_5","inline_mobile_article_6","inline_mobile_article_7","inline_mobile_article_8","inline_mobile_article_9"];
var desktopAdSizes = ["inline_desktop_article_1","inline_desktop_article_2","inline_desktop_article_3","inline_desktop_article_4"];

if($(".article-content").length != 0){
  var tags = $(".article-content").children();
  var adCount = 10;
  var currentAdCount = 0;
  //every x posts
  var maxChildCount = 15;
  var maxMobileChildCount = 9;

  //max ads per page
  var maxMobileAds = 9;
  var maxDesktopAds = 5;

  //check if the article is long.. if so spread the ads farther out.. based on the count.
  if(tags.length > maxMobileAds*maxMobileChildCount){
    maxMobileChildCount = Math.floor(tags.length/maxMobileAds);
    console.log("spreading Ads");
    console.log(maxMobileChildCount);
  }
  if(tags.length > maxDesktopAds*maxChildCount){
    maxChildCount = Math.floor(tags.length/maxDesktopAds);
    console.log("spreading Ads");
    console.log(maxChildCount);
  }

console.log("has above tags");
console.log(adNewspaper);
console.log(toplineCats);
var pageIsSponsored = (toplineCats.indexOf("sponsored-content") > -1);
console.log(pageIsSponsored);
console.log(adSlug);
console.log(adPagetype);
console.log($(".single-body").children());
console.log(tags);


$.each(tags,function(x,i){
  adCount++;
if(isMobile === true){
  if(adCount % maxMobileChildCount == 0 && !pageIsSponsored){
    console.log("mobile injection");
$(i).after('<div class="ad ad-inline mobile"><div data-cats="'+toplineCats+'" id="dfp_block_'+mobileAdSizes[currentAdCount]+'"><div id="'+mobileAdSizes[currentAdCount]+'"></div></div></div>');
googletag.display(mobileAdSizes[currentAdCount]);

    console.log("adding");
    var mapping;
    slots[mobileAdSizes[currentAdCount]] = googletag.defineSlot('/4848013/'+mobileAdSizes[currentAdCount], [[300, 250]], mobileAdSizes[currentAdCount]).addService(googletag.pubads());
    mapping = googletag.sizeMapping().
   addSize([0, 0], [300, 250]).
build();
    slots[mobileAdSizes[currentAdCount]].setTargeting('Newspapers', 'southforker');
    slots[mobileAdSizes[currentAdCount]].setTargeting('categories', toplineCats);
    slots[mobileAdSizes[currentAdCount]].setTargeting('slug', adSlug);
    slots[mobileAdSizes[currentAdCount]].setTargeting('pagetype', adPagetype);
    console.log(slots[mobileAdSizes[currentAdCount]]);
    console.log("*****");

    currentAdCount++;
}
}else{
  //desktop injection has been disabled
  if(adCount % maxChildCount == 0 && !pageIsSponsored && 1 == 2){
    console.log("desktop injection");
$(i).after('<div class="ad ad-inline desktop"><div  data-cats="'+toplineCats+'" id="dfp_block_'+desktopAdSizes[currentAdCount]+'"><div id="'+desktopAdSizes[currentAdCount]+'"></div></div></div>');
googletag.display(desktopAdSizes[currentAdCount]);

    console.log("adding");
    var mapping;
    slots[desktopAdSizes[currentAdCount]] = googletag.defineSlot('/4848013/'+desktopAdSizes[currentAdCount], [[400, 225]], desktopAdSizes[currentAdCount]).addService(googletag.pubads());
    mapping = googletag.sizeMapping().
   addSize([0, 0], []).
   addSize([1024, 768], [400, 225]).
 build();
    slots[desktopAdSizes[currentAdCount]].setTargeting('Newspapers', 'southforker');
    slots[desktopAdSizes[currentAdCount]].setTargeting('categories', toplineCats);
    slots[desktopAdSizes[currentAdCount]].setTargeting('slug', adSlug);
    slots[desktopAdSizes[currentAdCount]].setTargeting('pagetype', adPagetype);
    console.log(slots[desktopAdSizes[currentAdCount]]);

    currentAdCount++;
}
}

});




}

console.log("finished Loading");

console.log(slots);


    googletag.pubads().enableSingleRequest();
    googletag.pubads().collapseEmptyDivs(true);
    googletag.enableServices();
  });

}

function dropCap(target) {
  let text = $(target).html();
  text = text.replace(/^((\S+\s+){2}\S+)/, '<span class="lead-text">$1</span>');
  target.html(text);
}
